import styled, { css } from 'styled-components';
import { BusinessListCard } from '../../components/BusinessListCard/BusinessListCard';
import { useEffect, useState } from 'react';
import { getSearchCompanies } from '../../services/company.service';
import LeafletMap from '../../components/LeafletMap/LeafletMap';
import { BannerItem, CompanyListItem, CompanySearchFilters } from '../../types/api';
import { matchRoutes, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IState } from '../../store';
import { HorizontalBanner } from '../../components/HorizontalBanner/HorizontalBanner';
import { useTranslation } from 'react-i18next';
import { Pagination } from '../../components/Pagination/Pagination';
import { AdditionalFilters } from '../../components/SearchBar/AdditionalFilters/AdditionalFilters';
import { routes } from '../../constants/routes';
import classNames from 'classnames';
import { RecentReviews } from '../../components/RecentReviews/RecentReview';
import { AddCardNative } from '../../components/AdCard/AdCardNative';
// @ts-ignore
import { Helmet } from 'react-helmet';

const BusinessListPage = styled.div`
    ${(props) => css`
        display: flex;
        justify-content: center;
        &.reviews {
            border-top: 1px solid ${props.theme.colors.outlineLight};
            width: 100%;
            padding-top: 80px;
            ${!props.theme.screen.desktop &&
            css`
                padding-top: 32px;
            `}
        }
    `}
`;
const CardContainer = styled.div`
    ${(props) => css`
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 1254px;
        padding: 0 16px;
        ${!props.theme.screen.desktop &&
        css`
            grid-template-columns: 64.5% auto;
            gap: 16px;
        `}
        ${props.theme.screen.mobile &&
        css`
            grid-template-columns: 1fr;
        `}
    `}
`;
const Header = styled.h1`
    margin: 0;
    font-family: 'Albert-Regular';
    font-size: 32px;
    font-weight: 700;
    line-height: 38px;
`;

const SideBar = styled.div`
    ${(props) => css`
        width: 39%;
        &.hide {
            display: none;
        }
        &.mobile-size {
            .leaflet-container {
                height: 378px;
            }
        }

        ${!props.theme.screen.desktop &&
        css`
            width: 100%;
            order: 1;
        `}
    `}
`;

const Wrap = styled.div`
    ${(props) => css`
        position: sticky;
        top: 75px;
        width: 42.3vw;
        ${!props.theme.screen.desktop &&
        css`
            margin-bottom: 16px;
            width: 100%;
        `}
        ${props.theme.screen.desktop &&
        css`
            &.gate-active {
                top: 229px;
                width: 100%;
            }
        `}
    `}
`;

const Text = styled.div`
    font-family: 'Albert-Regular';
    font-size: 32px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: center;
    width: 59%;
`;

const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin: 8px 0;
`;
const FilterWrap = styled.div``;

const ListMapWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
`;
const ListMapButton = styled.div`
    ${(props) =>
        css`
            background-color: ${props.theme.colors.lightNeutral};
            padding: 10px;
            font-family: 'Albert-Regular';
            font-size: 12px;
            font-weight: 400;
            height: 14px;
            &.list {
                border-radius: 10px 0 0 10px;
            }
            &.map {
                border-radius: 0 10px 10px 0;
            }
            &.active {
                background-color: ${props.theme.colors.neutral};
            }
        `}
`;

const BusinessListWrap = styled.div`
    ${(props) => css`
        width: 59%;
        &.hide {
            display: none;
        }
        ${!props.theme.screen.desktop &&
        css`
            width: 100%;
            order: 2;
        `}
    `}
`;

const AdsWrapper = styled.div`
    ${(props) => css`
        background-color: ${props.theme.colors.greyBg};
        padding: 10px;
        margin-bottom: 30px;
    `}
`;

const AdsWrapperInner = styled.div`
    ${(props) => css`
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        width: 100%;
        gap: 10px;
        ${!props.theme.screen.desktop &&
        css`
            display: flex;
            justify-content: center;
        `}
    `}
`;

const AdsWrapperInnerWBQ = styled.div`
    ${(props) => css`
        width: 100%;
        ${!props.theme.screen.desktop &&
        css`
            display: flex;
            justify-content: center;
        `}
    `}
`;

export const BusinessList = () => {
    const { t } = useTranslation();
    const urlParams = useParams();
    const location = useLocation();
    const { state } = useLocation();
    const [companies, setCompanies] = useState<CompanyListItem[]>([]);
    const [shownCoordinates, setShownCoordiantes] = useState<CompanyListItem>();
    const { screenViewPort, bannersList, bannersFilteredByTopic, gateIsActive, bannersFilteredByKeyword } = useSelector(
        (state: IState) => state.global
    );
    const [listMapToggle, setListMapToggle] = useState<{ list: boolean; map: boolean }>({ list: true, map: false });

    useEffect(() => {
        if (state?.companies) {
            return;
        }
        const filters: CompanySearchFilters = {};
        if (urlParams.categoryId && !urlParams.topicId) {
            filters.category = urlParams.categoryId;
        }
        if (urlParams.categoryId && urlParams.topicId && !urlParams.headId) {
            filters.topic = urlParams.topicId;
        }
        if (urlParams.headId) {
            filters.head = urlParams.headId;
        }
        getSearchCompanies(1, filters).then((res) => {
            setCompanies(res.data);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    useEffect(() => {
        if (state?.companies) {
            setCompanies(state.companies);
        }
    }, [state?.companies]);

    const cardsInView = (card: CompanyListItem) => {
        setShownCoordiantes(card);
    };

    const capitalize = (str: string) => {
        if (str) {
            const string = str.toLowerCase();
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
        return;
    };

    const matchRoute = () => {
        const match = matchRoutes(allowedGateRoutes, location);
        return !!match;
    };

    const allowedGateRoutes = routes.filter((route) => !route.disableGate);

    const isGateAllowed = gateIsActive && screenViewPort === 'desktop' && matchRoute();

    const bannersSliceByIndexBig = () => {
        let currentSlice: BannerItem[] = [];

        if (urlParams?.topicId && bannersFilteredByTopic.WBSQ.length > 0) {
            currentSlice.push(bannersFilteredByTopic.WBSQ[0]);
        } else if (bannersFilteredByKeyword.WBZSQ.length > 0) {
            currentSlice.push(bannersFilteredByKeyword.WBZSQ[0]);
        } else if (bannersList.WBQ.length > 0) {
            currentSlice.push(bannersList.WBQ[0]);
        }

        return currentSlice;
    };

    const bannersSliceByIndex = () => {
        const newSlice = (array: BannerItem[], ...banner: BannerItem[]) => {
            if (array.length < 6) {
                return [...array, ...banner];
            }
            return array;
        };
        let currentSlice: BannerItem[] = [];

        if (urlParams?.topicId && bannersFilteredByTopic.WBS.length > 0) {
            currentSlice.push(...bannersFilteredByTopic.WBS.slice(0, 5));
        }

        if (bannersFilteredByKeyword.WBZS.length > 0) {
            currentSlice.push(...bannersFilteredByKeyword.WBZS);
        }

        if (bannersList.WBM.length > 0) {
            currentSlice = newSlice(currentSlice, ...bannersList.WBM);
        }

        return currentSlice;
    };

    const insertBanners = (index: number) => {
        if (companies.length < 3) {
            return true;
        }

        if (screenViewPort === 'desktop') {
            return index === 2;
        }
        return index === 2 || index === 4 || index === 6;
    };

    const bannersSlice = (index: number) => {
        if (companies.length < 3) {
            return bannersSliceByIndex().slice(0, 3);
        }

        if (screenViewPort === 'desktop') {
            if (index === 2) {
                return bannersSliceByIndex().slice(0, 3);
            }

            if (index === 5) {
                return bannersSliceByIndex().slice(3, 6);
            }
        }
        switch (index) {
            case 0:
                return bannersSliceByIndex().slice(0, 1);
            case 3:
                return bannersSliceByIndex().slice(1, 2);
            case 6:
                return bannersSliceByIndex().slice(2, 3);
            case 9:
                return bannersSliceByIndex().slice(3, 4);
            case 12:
                return bannersSliceByIndex().slice(4, 5);
            case 15:
                return bannersSliceByIndex().slice(5, 6);
            default:
                return [];
        }
    };

    return (
        <>
            <BusinessListPage>
                <Helmet>
                    <title>{state?.topicOrHeadName}</title>
                </Helmet>
                <CardContainer>
                    <Header>{capitalize(state?.topicOrHeadName)}</Header>
                    {screenViewPort !== 'desktop' && (
                        <ButtonContainer>
                            <FilterWrap>
                                <AdditionalFilters />
                            </FilterWrap>
                            <ListMapWrap>
                                <ListMapButton
                                    className={classNames('list', {
                                        active: listMapToggle.list,
                                    })}
                                    onClick={() => setListMapToggle({ list: true, map: false })}
                                >
                                    {t('list')}
                                </ListMapButton>
                                <ListMapButton
                                    className={classNames('map', {
                                        active: listMapToggle.map,
                                    })}
                                    onClick={() => setListMapToggle({ list: false, map: true })}
                                >
                                    {t('map')}
                                </ListMapButton>
                            </ListMapWrap>
                        </ButtonContainer>
                    )}
                    {screenViewPort === 'desktop' && (
                        <HorizontalBanner inElement={true} isGateAllowed={isGateAllowed} />
                    )}
                    {screenViewPort === 'desktop' && <AdditionalFilters />}
                    <BusinessListWrap
                        className={classNames({ hide: listMapToggle.map && screenViewPort !== 'desktop' })}
                    >
                        {companies.length > 0 ? (
                            <>
                                {/* <VerticalAdCards />
                                <BusinessListHorizontalAd /> */}
                                {companies.map((company, index) => (
                                    <>
                                        <BusinessListCard key={index} company={company} setCardInView={cardsInView} />
                                        {index === 0 && bannersList.WBQ[0] && (
                                            <AdsWrapper>
                                                <AdsWrapperInnerWBQ>
                                                    {bannersSliceByIndexBig().map((banner) => {
                                                        return (
                                                            <AddCardNative
                                                                banner={banner}
                                                                header={banner.header}
                                                                adHref={banner.url}
                                                                adUrl={banner.image}
                                                            />
                                                        );
                                                    })}
                                                </AdsWrapperInnerWBQ>
                                            </AdsWrapper>
                                        )}
                                        {insertBanners(index) && bannersSliceByIndex().length > 0 && (
                                            <AdsWrapper>
                                                <AdsWrapperInner>
                                                    {bannersSlice(index).map((banner) => {
                                                        return (
                                                            <AddCardNative
                                                                banner={banner}
                                                                header={banner.header}
                                                                adHref={banner.url}
                                                                adUrl={banner.image}
                                                            />
                                                        );
                                                    })}
                                                </AdsWrapperInner>
                                            </AdsWrapper>
                                        )}
                                    </>
                                ))}
                                <Pagination setCompanies={setCompanies} />
                            </>
                        ) : (
                            <Text>{t('no_companies_found')}</Text>
                        )}
                    </BusinessListWrap>
                    <>
                        <SideBar
                            className={classNames(
                                { hide: !listMapToggle.map && screenViewPort !== 'desktop' },
                                { 'mobile-size': screenViewPort !== 'desktop' }
                            )}
                        >
                            <Wrap className={classNames({ 'gate-active': gateIsActive })}>
                                <LeafletMap
                                    coordinates={screenViewPort === 'desktop' ? shownCoordinates?.address : undefined}
                                    companies={
                                        screenViewPort !== 'desktop' && listMapToggle.map ? companies : undefined
                                    }
                                />
                            </Wrap>
                        </SideBar>
                    </>
                </CardContainer>
            </BusinessListPage>
            <BusinessListPage className="reviews">
                <CardContainer>
                    <RecentReviews noAdsSlider={true} />
                </CardContainer>
            </BusinessListPage>
        </>
    );
};
