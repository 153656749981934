import { Outlet, useLocation, useParams, matchRoutes } from 'react-router-dom';
import Box from '@mui/material/Box';
import styled, { css } from 'styled-components';
import { SearchBar } from '../../components/SearchBar/SearchBar';
import LandingBackground from '../../assets/images/home_background.png';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '../../store';
import { HorizontalBanner } from '../../components/HorizontalBanner/HorizontalBanner';
import { Footer } from '../../components/Footer/Footer';
import { ROUTE_NAMES, routes } from '../../constants/routes';
import { AdGate } from '../../components/AdGate/AdGate';
import { useEffect, useState } from 'react';
import {
    setBannersByKeyword,
    setBannersByTopic,
    setGateIsActive,
    setBannersByCategory,
} from '../../store/global.slice';
import { Breadcrumbs } from '../../components/Breadcrumbs/Breadcrumbs';
import { PopularCategoriesBar } from '../../components/Categories/PopularCategoriesBar';

const Page = styled.div`
    ${(props) => css`
        margin-top: calc(119px + 92px);
        min-height: calc(100vh - 303px);
        ${props.theme.screen.mobile &&
        css`
            margin-top: calc(142px + 338px);
            min-height: calc(100vh - 744px);
        `}
    `}
`;

const Background = styled.div`
    ${(props) => css`
        position: absolute;
        transform: translateY(-75px);
        width: 100%;
        height: auto;
        background-image: url(${LandingBackground});
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top;
        z-index: 3;
        padding-top: 1px;
        ${props.theme.screen.mobile &&
        css`
            &.mobile-search-modal-in-front {
                z-index: 11;
            }
        `}
        :after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.5);
        }
        &.not-home {
            background: ${props.theme.colors.neutral};
        }
        &.not-home:after {
            background: none;
        }
    `}
`;

const Wrap = styled.div`
    ${(props) => css`
        ${props.theme.screen.desktop &&
        css`
            &.gate-active {
                min-height: 682px;
                padding-top: 154px;
                margin-top: -100vh;
            }
        `}
    `}
`;

export const MainPageLayout = () => {
    const urlParams = useParams();
    const location = useLocation();
    const dispatch = useDispatch();
    const {
        showAdvancedMobileSearch,
        screenViewPort,
        bannersList,
        gateIsActive,
        bannersFilteredByKeyword,
        bannersFilteredByTopic,
    } = useSelector((state: IState) => state.global);

    const [categoryId, setCategoryId] = useState<string | undefined>('initial');

    const checkBannersLength = () => {
        return Object.keys(bannersList).some((key) => bannersList[key as keyof typeof bannersList].length > 0);
    };

    const { state } = useLocation();

    useEffect(() => {
        if (
            (bannersFilteredByTopic.W001.length > 0 && bannersList.W001[0].image.includes('.png')) ||
            (bannersFilteredByKeyword.W003.length > 0 && bannersList.W003[0].image.includes('.png')) ||
            (bannersList.W005.length > 0 && bannersList.W005[0].image.includes('.png'))
        ) {
            dispatch(setGateIsActive(true));
            return;
        }

        dispatch(setGateIsActive(false));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bannersFilteredByTopic.W001, bannersFilteredByKeyword.W003, bannersList.W005]);

    useEffect(() => {
        dispatch(setBannersByTopic(urlParams?.topicId));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bannersList, urlParams?.topicId]);

    useEffect(() => {
        if (categoryId !== urlParams?.categoryId && checkBannersLength()) {
            setCategoryId(urlParams?.categoryId);
            dispatch(setBannersByCategory(urlParams?.categoryId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [urlParams?.categoryId]);

    useEffect(() => {
        dispatch(setBannersByKeyword(state?.filters?.query));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state?.filters]);

    const matchRoute = () => {
        const match = matchRoutes(allowedGateRoutes, location);
        return !!match;
    };

    const allowedGateRoutes = routes.filter((route) => !route.disableGate);

    const isGateAllowed = gateIsActive && screenViewPort === 'desktop' && matchRoute();

    return (
        <>
            <Box>
                <Background
                    className={classNames({
                        'not-home': location.pathname !== '/',
                        'mobile-search-modal-in-front': showAdvancedMobileSearch,
                    })}
                >
                    <SearchBar />
                    <PopularCategoriesBar />
                </Background>
                <Page>
                    {isGateAllowed && <AdGate />}
                    <Wrap className={classNames({ 'gate-active': gateIsActive })}>
                        {screenViewPort === 'desktop' && (location.pathname === '/' || urlParams?.companyId) && (
                            <HorizontalBanner isGateAllowed={isGateAllowed} />
                        )}
                        {location.pathname !== ROUTE_NAMES.HOME && <Breadcrumbs />}
                        <Outlet />
                    </Wrap>
                </Page>
                <Footer />
            </Box>
        </>
    );
};
