import i18n from '../i18n/config';

import CategoryBreadcrumb from '../components/Breadcrumbs/DynamicMatchers/CategoryBreadcrumb';
import CompanyBreadcrumb from '../components/Breadcrumbs/DynamicMatchers/CompanyBreadcrumb';
import TopicBreadcrumb from '../components/Breadcrumbs/DynamicMatchers/TopicBreadcrumb';
import TopicHeadBreadcrumb from '../components/Breadcrumbs/DynamicMatchers/TopicHeadBreadcrumb';

export const ROUTE_NAMES = {
    PAGE_NOT_FOUND: '*',
    HOME: '/',
    PRODUCTS: '/produktai',
    USEFUL_INFORMATION: '/naudinga-informacija',
    COMPANY_CARD: 'imones-kortele/:companyId',
    EU_PROJECTS: 'informacija/es-projektai',
    FULL_BUSINESS_LIST: '/imoniu-katalogas',
    BUSINESS_LIST: '/imoniu-sarasas',
    BUSINESS_LIST_CATEGORY: ':categoryId',
    BUSINESS_LIST_TOPIC: ':topicId',
    BUSINESS_LIST_HEAD: ':headId',
    SEARCH: 'paieska',
};

export const routes = [
    {
        path: ROUTE_NAMES.PAGE_NOT_FOUND,
        breadcrumb: null,
    },
    {
        path: '/social-login',
        breadcrumb: null,
    },
    {
        path: ROUTE_NAMES.HOME,
        breadcrumb: i18n.t('main_page'),
        disableGate: false,
    },
    {
        path: ROUTE_NAMES.PRODUCTS,
        breadcrumb: i18n.t('products'),
    },
    {
        path: ROUTE_NAMES.USEFUL_INFORMATION,
    },
    {
        path: ROUTE_NAMES.EU_PROJECTS,
        breadcrumb: i18n.t('EU_projects'),
    },
    {
        path: ROUTE_NAMES.SEARCH,
        breadcrumb: i18n.t('companies_catalogue'),
        children: [
            {
                path: ROUTE_NAMES.COMPANY_CARD,
                breadcrumb: CompanyBreadcrumb,
            },
        ],
    },
    {
        path: ROUTE_NAMES.BUSINESS_LIST,
        breadcrumb: null,
        children: [
            {
                path: ROUTE_NAMES.BUSINESS_LIST_CATEGORY,
                breadcrumb: CategoryBreadcrumb,
                children: [
                    {
                        path: ROUTE_NAMES.BUSINESS_LIST_TOPIC,
                        breadcrumb: TopicBreadcrumb,
                        children: [
                            {
                                path: ROUTE_NAMES.COMPANY_CARD,
                                breadcrumb: CompanyBreadcrumb,
                            },
                            {
                                path: ROUTE_NAMES.BUSINESS_LIST_HEAD,
                                breadcrumb: TopicHeadBreadcrumb,
                                children: [
                                    {
                                        path: ROUTE_NAMES.COMPANY_CARD,
                                        breadcrumb: CompanyBreadcrumb,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: ROUTE_NAMES.COMPANY_CARD,
                        breadcrumb: CompanyBreadcrumb,
                    },
                ],
            },
            {
                path: ROUTE_NAMES.COMPANY_CARD,
                breadcrumb: CompanyBreadcrumb,
            },
        ],
    },
    {
        path: ROUTE_NAMES.FULL_BUSINESS_LIST,
        breadcrumb: i18n.t('business-list'),
        children: [
            {
                path: ROUTE_NAMES.BUSINESS_LIST_CATEGORY,
                breadcrumb: CategoryBreadcrumb,
                children: [
                    {
                        path: ROUTE_NAMES.BUSINESS_LIST_TOPIC,
                        breadcrumb: TopicBreadcrumb,
                        children: [
                            {
                                path: ROUTE_NAMES.COMPANY_CARD,
                                breadcrumb: CompanyBreadcrumb,
                            },
                            {
                                path: ROUTE_NAMES.BUSINESS_LIST_HEAD,
                                breadcrumb: TopicHeadBreadcrumb,
                                children: [
                                    {
                                        path: ROUTE_NAMES.COMPANY_CARD,
                                        breadcrumb: CompanyBreadcrumb,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: ROUTE_NAMES.COMPANY_CARD,
                        breadcrumb: CompanyBreadcrumb,
                    },
                ],
            },
            {
                path: ROUTE_NAMES.COMPANY_CARD,
                breadcrumb: CompanyBreadcrumb,
            },
        ],
    },
];
